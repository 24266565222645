.root {
  display: block;
  height: 24px;
  margin-bottom: 1rem;
  margin-left: -4px;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.39, 0.57, 0.56, 1);
}

.loaded {
  height: auto;
  opacity: 1;
}
