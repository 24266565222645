.checkboxes {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: spacing(2.5);
  margin-bottom: spacing(2);
  text-transform: capitalize;

  > *:not(:first-child) {
    margin-left: spacing(5);
  }

  @media ($phone-down) {
    > *:not(:first-child) {
      margin-left: spacing(3);
    }
  }
}

.checkboxWrapper {
  .checkedCheckbox {
    color: var(--pink-dark) !important;
  }

  .emptyCheckbox,
  .checkedCheckbox {
    :focus ~ & {
      color: var(--pink-dark);
    }
  }
}

.fields {
  padding-top: 0;
}

.fieldset {
  padding-top: 0;

  > * + * {
    margin-top: spacing(1.2);
  }
}

.input,
.inputWrapper input,
.inputWrapper select {
  height: 48px !important;
  font-family: var(--fontFamily-sofia);
  line-height: 20px;
  color: var(--vader) !important;
  background-color: var(--subdued) !important;
  border: 0 !important;

  &:focus {
    outline: var(--pink-dark) auto 1px !important;
  }

  .textarea {
    height: auto;
  }
}

.inputIcon {
  margin-top: 1px;
  fill: var(--pink-dark);

  &::before {
    background-color: var(--pink-dark);
  }

  > svg {
    margin-top: 1px;
  }
}

.divider {
  margin: spacing(3.5, 0);
}
