.title {
  font-family: var(--fontFamily-sofia);
  font-size: 1.5rem;
  font-weight: var(--bold);
  line-height: 1em;
}

.size {
  width: 100%;
}

.fixedSize {
  overflow: visible;
}

.dummy {
  z-index: var(--z-navColor);
  transition: background-color 0.3s;

  @media ($phone-up) {
    opacity: 0;
  }
}

.root {
  position: relative;
  top: 0;
  z-index: var(--z-nav);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: spacing(1) 0;
  background-color: var(--white);

  @media ($desktop-up) {
    padding: spacing(2) 0;
  }
}

body:global(.accessibility-mode) .burger {
  &:focus {
    outline: 2px solid blue !important;
  }
}

.topRow {
  width: 100%;
  height: calc(var(--navHeight) - spacing(2));
}

.row {
  width: 100%;
}

.paddedRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(1, 2);
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-navBackdrop);
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: background-color 0.35s cubic-bezier(0.85, 0, 0.15, 1);
}

.backdropVisible {
  pointer-events: all;
  opacity: 1;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menuItemContainer {
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
}

.menuItem {
  height: auto;
}

.rightPart {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > * + * {
    margin-left: spacing(1);
  }
}

.notFixed {
  position: relative;
}

.languagePicker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100px;
  margin-bottom: spacing(7);
  border-top: 1px solid var(--lightgrey);

  @media ($desktop-up) {
    margin-bottom: 0;
  }
}
