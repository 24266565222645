.root {
  --pill-size: spacing(3);

  &.large {
    --pill-size: spacing(4);
    padding: spacing(1) calc(var(--pill-size) / 2);
  }

  &.hasIconLeft {
    padding-left: 2em;

    &.large {
      padding-left: spacing(4);
    }
  }

  &.hasIconRight {
    padding-right: 2em;

    &.large {
      padding-right: spacing(4);
    }
  }
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: spacing(0.5) calc(var(--pill-size) / 2);
  overflow: hidden;
  font-family: var(--fontFamily-sofia);
  font-size: 0.75rem;
  font-weight: var(--bold);
  line-height: 1em;
  color: rgba(0, 0, 0, 0.75);
  border: 2px solid;
  border-radius: var(--pill-size);

  &.margin {
    & + & {
      margin-left: 0.5rem;
    }
  }
}

.icon {
  position: absolute;
  width: auto;
  height: 100%;
}

.icon-left {
  left: 0;
}

.icon-right {
  right: 0;
}

.pink {
  background-color: var(--baby-pink);
  border-color: var(--baby-pink);
}

.green {
  color: var(--white);
  background-color: var(--soft-green);
  border-color: var(--soft-green);
}

.offwhite {
  background-color: var(--offwhite);
  border-color: var(--offwhite);
}

.subdued {
  background-color: var(--subdued);
  border-color: var(--subdued);
}

.error {
  color: var(--error-red);
  background: transparent;
  border-color: transparent;
}

.lightgrey {
  background: var(--lightgrey);
  border-color: var(--lightgrey);
}
