.fieldset {
  composes: fieldset from '../styles/TimeslotsForm.module.css';

  :global(.DayPickerInput-Overlay) {
    margin-top: 1px;
  }

  :global(.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)) {
    background-color: var(--pink-dark);
  }

  :global(.DayPicker-Day--today) {
    color: var(--pink-dark);
  }

  :global(.DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover) {
    background-color: var(--baby-pink);
  }

  @media ($phone-down) {
    :global(.DayPickerInput-OverlayWrapper),
    :global(.DayPickerInput-Overlay),
    :global(.DayPicker),
    :global(.DayPicker-wrapper) {
      width: 100%;
    }

    :global(.DayPicker-NavButton) {
      top: 1.5em;
      right: 2.25em;
    }

    :global(.DayPicker-Month) {
      margin: 2em 2em 0.5em;
    }

    :global(.DayPicker-Day) {
      padding: 0.75em;
    }
  }

  @media (max-width: 375px) {
    :global(.DayPicker-NavButton) {
      top: 1.5em;
      right: 2.25em;
    }

    :global(.DayPicker-Month) {
      margin: 1.75em 1em 0.5em;
    }

    :global(.DayPicker-Day) {
      padding: 0.65em;
    }
  }
}

.dayPickerOverlay {
  margin-top: 2px !important;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;

  > * {
    margin-top: 0;
    margin-left: 0;
  }
}

.servicePill {
  /* It is what it is */
  margin-left: 0 !important;
  font-size: 16px;
  font-weight: 400;
}
