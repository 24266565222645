.root {
  margin: 0;
  cursor: pointer;

  span {
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: 400;
  }
}

.hidden {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
