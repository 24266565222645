.steps {
  & > * + * {
    margin: 100px 0 0 0;

    @media ($desktop-up) {
      margin: 0 0 0 100px;
    }
  }

  @media ($desktop-up) {
    display: flex;
    justify-content: center;
  }
}

.actionCards {
  @media ($desktop-up) {
    display: flex;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > * {
    margin-top: 10px;
  }

  > *:nth-child(2) {
    margin-top: 25px;
  }

  @media ($desktop-up) {
    flex: 1;
    width: 100%;
  }
}

.cta {
  width: 100%;
  margin-top: auto;

  > a {
    margin-top: 32px;

    @media ($desktop-up) {
      margin-top: 64px;
    }
  }
}

.actionsCta {
  width: 100%;
  margin-top: auto;

  > a,
  button {
    margin-top: 32px;
  }
}

.introContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ($phone-up) {
    max-width: calc(var(--phone-breakpoint) - var(--sideMargin-desktop));
    padding-right: spacing(4);
  }
}

.searchTimeslotsFormContainer {
  padding-top: spacing(3);
}

.desktopIntro {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  img {
    width: auto;
    max-width: 360px;
    height: 620px;
  }
}

.phoneOnly {
  @media ($tablet-landscape-up) {
    display: none;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.desktopOnly {
  display: none;

  @media ($tablet-landscape-up) {
    display: block;
  }
}

.overflowYSwooshBlock {
  overflow-y: visible;
}

.swoosh {
  z-index: var(--z-navSwoosh);
  height: 70px;

  @media ($phone-up) {
    display: none;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--baby-pink);
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-top: spacing(2);

  @media ($phone-down) {
    align-items: center;
  }

  button {
    width: 100%;
  }

  & > * {
    width: 100%;
    max-width: 400px;
    margin-top: spacing(2);
  }
}

.swooshMobileBlock {
  overflow-y: revert;
}

.intro {
  display: flex;
  justify-content: center;

  &.mobile {
    padding-bottom: spacing(2);
  }
}

.explanation {
  padding: 0;

  @media ($phone-down) {
    padding: 20px;
  }

  @media ($phone-up) {
    padding: 20px;
  }
}

.innerExplanation {
  & img {
    width: 100%;
    height: auto;
  }
  padding: 0;
}

.pointsWrapper ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: spacing(2);

  @media ($phone-down) {
    grid-template-columns: 1fr;
  }

  & li {
    display: flex;
    gap: spacing(1);

    &::before {
      display: block;
      width: 20px;
      height: 20px;
      content: '';
      background-image: url('../../icons/done_green.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

.harmonyLogo {
  height: 20px;
  margin-right: spacing(2);
  margin-bottom: 5px;
}

.rocheLogo {
  height: 30px;
}

.actions {
  width: 100%;
  min-width: var(--content-min-width);
  max-width: var(--content-max-width);
  padding: 0 var(--sideMargin);
  margin: spacing(6) auto auto auto;

  @media ($phone-down) {
    margin: auto auto spacing(2) auto;
  }

  > div {
    max-width: 600px;
    padding: 0 spacing(2);
    margin: 0 auto;
  }
}
